import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    Theme,
    AppBarProps,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Menu as MenuIcon } from '@mui/icons-material';

import { Link, useHistory } from 'react-router-dom';
import { JoebosoftLogo } from './logos/joebosoft';
import { useState } from 'react';
import clsx from 'clsx';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            transition: 'all linear 350ms',
        },
        showToolbar: {
            top: 0,
        },
        hideToolbar: {
            top: -64,
        },
        headerLink: {
            marginLeft: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: 32,
                '&:first-child': {
                    marginTop: 0,
                },
            },
        },
    })
);

export const SharedToolbar: React.FC<
    AppBarProps & {
        scrollThreshold?: number;
        triggerElementId?: string;
        colors?: { background?: string; fixedBackground?: string; font?: string; fixedFont?: string };
    }
> = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const {
        sx,
        triggerElementId = '',
        scrollThreshold = 100,
        colors = { fixedBackground: theme.palette.primary.dark },
        ...other
    } = props;
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showFixed, setShowFixed] = useState(false);
    const [fixedToolbarClass, setFixedToolbarClass] = useState(classes.hideToolbar);

    useScrollPosition(
        ({ currPos }) => {
            const offset =
                ((triggerElementId && document.getElementById(triggerElementId)?.offsetTop) || window.innerHeight) -
                scrollThreshold;
            const isPastThreshold = currPos.y >= offset;

            const targetClass = isPastThreshold ? classes.showToolbar : classes.hideToolbar;
            if (showFixed !== isPastThreshold) setShowFixed(isPastThreshold);
            if (fixedToolbarClass !== targetClass) setFixedToolbarClass(targetClass);
        },
        [scrollThreshold, classes, showFixed, fixedToolbarClass, triggerElementId],
        undefined,
        true,
        200
    );

    const AppBarContents = (
        <Toolbar>
            <JoebosoftLogo
                style={{ height: 28, flex: '0 0 auto', cursor: 'pointer' }}
                onClick={(): void => {
                    history.push('/');
                }}
            />
            <Box sx={{ flex: '1 1 0px' }} />
            {!xs && (
                <>
                    <Typography component={Link} to={'/'} className={classes.headerLink}>
                        Home
                    </Typography>
                    <Typography component={Link} to={'/services'} className={classes.headerLink}>
                        Services
                    </Typography>
                    {/* <Typography component={Link} to={'/portfolio'} className={classes.headerLink}>
                        Portfolio
                    </Typography> */}
                    {/* <Typography component={Link} to={'/blog'} className={classes.headerLink}>
                        Blog
                    </Typography> */}
                    <Typography component={Link} to={'/contact'} className={classes.headerLink}>
                        Contact
                    </Typography>
                </>
            )}
            {xs && (
                <>
                    <IconButton onClick={() => setDrawerOpen((prev) => !prev)} color={'inherit'}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor={'right'}
                        open={drawerOpen}
                        onClose={(): void => setDrawerOpen(false)}
                        PaperProps={{
                            sx: {
                                width: 300,
                                maxWidth: '80%',
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                justifyContent: 'flex-start',
                            }}
                        >
                            <JoebosoftLogo style={{ height: 48 }} />
                            <Typography
                                variant={'h5'}
                                component={Link}
                                to={'/'}
                                onClick={(): void => setDrawerOpen(false)}
                                className={classes.headerLink}
                            >
                                Home
                            </Typography>
                            <Typography
                                variant={'h5'}
                                component={Link}
                                to={'/services'}
                                onClick={(): void => setDrawerOpen(false)}
                                className={classes.headerLink}
                            >
                                Services
                            </Typography>
                            {/* <Typography
                                variant={'h5'}
                                component={Link}
                                to={'/portfolio'}
                                onClick={(): void => setDrawerOpen(false)}
                                className={classes.headerLink}
                            >
                                Portfolio
                            </Typography> */}
                            {/* <Typography
                                variant={'h5'}
                                component={Link}
                                to={'/blog'}
                                onClick={(): void => setDrawerOpen(false)}
                                className={classes.headerLink}
                            >
                                Blog
                            </Typography> */}
                            <Typography
                                variant={'h5'}
                                component={Link}
                                to={'/contact'}
                                onClick={(): void => setDrawerOpen(false)}
                                className={classes.headerLink}
                            >
                                Contact
                            </Typography>
                        </Box>
                    </Drawer>
                </>
            )}
        </Toolbar>
    );

    return (
        <>
            <AppBar
                position={'relative'}
                color={'secondary'}
                sx={Object.assign(
                    { backgroundColor: colors.background || 'transparent', color: colors.font || 'white', zIndex: 100 },
                    sx
                )}
                // className={clsx(classes.toolbar, fixedToolbarClass)}
                elevation={0}
                {...other}
            >
                {AppBarContents}
            </AppBar>
            <AppBar
                position={'fixed'}
                color={'secondary'}
                sx={Object.assign(
                    { backgroundColor: colors.fixedBackground, color: colors.fixedFont, zIndex: 100 },
                    sx
                )}
                className={clsx(classes.toolbar, fixedToolbarClass)}
                // elevation={showFixed ? 4 : 0}
                {...other}
            >
                {AppBarContents}
            </AppBar>
        </>
    );
};
