import { GitHub, Mail } from '@mui/icons-material';
import { Box, IconButton, Typography, Theme, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { /*Link,*/ useHistory } from 'react-router-dom';
import { JoebosoftLogo } from './logos/joebosoft';
import { JoebosoftLogoStacked } from './logos/joebosoft-stack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hyperlink: {
            display: 'block',
            fontWeight: 300,
            marginTop: theme.spacing(1),
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        tagline: {
            marginTop: theme.spacing(2),
            fontWeight: 300,
        },
        linkWrapper: {
            margin: `0 ${theme.spacing(8)}`,
            // eslint-disable-next-line no-useless-computed-key
            ['@media (max-width:849px)']: {
                margin: `${theme.spacing(4)} 0`,
            },
        },
    })
);

export const Footer: React.FC = (props) => {
    const classes = useStyles();
    const stack = useMediaQuery('(max-width:849px)');
    const history = useHistory();
    return (
        <Box sx={{ backgroundColor: 'primary.dark', color: 'white' }}>
            <Box
                sx={{
                    padding: '48px 10vw',
                    display: stack ? 'block' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    textAlign: stack ? 'center' : 'left',
                }}
            >
                <Box sx={{}}>
                    {stack ? (
                        <JoebosoftLogoStacked
                            style={{ height: 80, marginBottom: 16, cursor: 'pointer' }}
                            onClick={(): void => history.push('/')}
                        />
                    ) : (
                        <JoebosoftLogo
                            style={{ height: 48, cursor: 'pointer' }}
                            onClick={(): void => history.push('/')}
                        />
                    )}
                    <Box sx={{ ml: stack ? 0 : 7 }}>
                        <Typography variant={'subtitle1'}>
                            {`Software Design &`}
                            <br style={{ display: stack ? 'none' : 'block' }} />
                            {`${stack ? ' ' : ''}Development Consulting`}
                        </Typography>
                        <Typography className={classes.tagline}>
                            {`We proudly support US-based`}
                            <br />
                            {`and independently-owned businesses`}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.linkWrapper}>
                    {/*   <Typography>EXPLORE</Typography>
                    <Typography component={Link} to={'/about'} className={classes.hyperlink}>
                        ABOUT
                    </Typography>
                    <Typography component={Link} to={'/services'} className={classes.hyperlink}>
                        SERVICES
                    </Typography>
                    <Typography component={Link} to={'/portfolio'} className={classes.hyperlink}>
                        PORTFOLIO
                    </Typography>
                    <Typography component={Link} to={'/blog'} className={classes.hyperlink}>
                        BLOG
                    </Typography>*/}
                </Box>
                <Box>
                    <Typography>CONNECT</Typography>
                    <Typography sx={{ fontWeight: 300, mt: 1 }}>
                        6521 Steubenville Pike #1018
                        <br />
                        Pittsburgh, PA 15205
                    </Typography>
                    {/* <Typography sx={{ mt: 2, fontWeight: 300 }}>+1 703.868.8696</Typography> */}
                    <Box>
                        <IconButton
                            color={'inherit'}
                            onClick={(): void => {
                                window.open('https://github.com/joebosoft', '_blank');
                            }}
                        >
                            <GitHub />
                        </IconButton>
                        <IconButton
                            color={'inherit'}
                            onClick={(): void => {
                                history.push('/contact');
                            }}
                        >
                            <Mail />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Typography
                variant={'button'}
                sx={{
                    display: 'block',
                    padding: 2,
                    fontSize: 12,
                    margin: '0 auto',
                    fontWeight: 300,
                    textAlign: 'center',
                }}
            >{`©${new Date().getFullYear()} Joebosoft LLC`}</Typography>
        </Box>
    );
};
