import { HTMLAttributes } from 'react';

export const JoebosoftLogo: React.FC<HTMLAttributes<SVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 48" {...props}>
        <g style={{ fill: 'currentColor' }}>
            <g>
                <path d="M65.30519,36.00761a6.9309,6.9309,0,0,1-5.03572,1.91125A6.53257,6.53257,0,0,1,55,35.58778L57.00518,33.769a3.99788,3.99788,0,0,0,3.26429,1.67929c2.378,0,4.243-1.72625,4.243-5.03644V10.08114h2.75126V30.41188A7.646,7.646,0,0,1,65.30519,36.00761Z" />
                <path d="M82.74522,36.05457a6.08494,6.08494,0,0,1-8.76644,0c-1.44554-1.72625-1.77179-4.7575-1.77179-10.3518,0-5.59572.32625-8.627,1.77179-10.3518a5.97877,5.97877,0,0,1,8.76644,0c1.44554,1.72482,1.77179,4.75608,1.77179,10.3518C84.517,31.29707,84.19076,34.32832,82.74522,36.05457ZM80.69343,16.8424a3.0028,3.0028,0,0,0-2.33143-1.073,3.10431,3.10431,0,0,0-2.378,1.073c-1.07268,1.30643-1.1659,3.777-1.1659,8.86037,0,5.082.09322,7.507,1.1659,8.812a3.1043,3.1043,0,0,0,2.378,1.073,3.0028,3.0028,0,0,0,2.33143-1.073c1.07268-1.305,1.2125-3.73,1.2125-8.812C81.90593,20.61937,81.76611,18.14883,80.69343,16.8424Z" />
                <path d="M91.27934,26.07563c0,4.84858.13982,7.22662,1.2125,8.43912a3.031,3.031,0,0,0,2.378,1.073,3.13038,3.13038,0,0,0,2.378-.93214,4.83854,4.83854,0,0,0,1.07268-3.35857h2.56446c-.0466,2.28553-.513,3.73143-1.72518,4.98947a5.98721,5.98721,0,0,1-4.29,1.63232,5.51259,5.51259,0,0,1-4.29-1.72482c-1.58536-1.67929-1.91161-4.80305-1.91161-10.49127,0-5.68965.32625-8.86037,1.91161-10.53966a6.08221,6.08221,0,0,1,8.4868,0c1.67857,1.81876,1.865,5.1759,1.865,10.91252ZM97.1547,16.8424a2.93125,2.93125,0,0,0-2.33143-1.073,3.03106,3.03106,0,0,0-2.378,1.073c-.9325,1.073-1.11929,3.12376-1.16589,7.13412H98.3206C98.274,19.96616,98.0872,17.91543,97.1547,16.8424Z" />
                <path d="M116.041,36.241a4.73648,4.73648,0,0,1-3.68376,1.67786,4.43528,4.43528,0,0,1-2.70465-.83964,5.03921,5.03921,0,0,1-1.35232-1.44447V37.546h-2.56447V10.08114h2.61108v8.41635a4.198,4.198,0,0,1,1.30571-1.39893,4.71763,4.71763,0,0,1,2.70465-.74571,4.61983,4.61983,0,0,1,3.68376,1.67928c1.44553,1.67786,1.77178,3.20488,1.77178,9.12792C117.81276,33.08166,117.48651,34.56171,116.041,36.241Zm-2.05179-16.484a3.05414,3.05414,0,0,0-2.28483-1.073,3.21713,3.21713,0,0,0-2.23822.88661,4.36434,4.36434,0,0,0-1.11928,3.26464V31.438a4.48314,4.48314,0,0,0,1.11928,3.31018,3.17852,3.17852,0,0,0,2.23822.83964,3.05413,3.05413,0,0,0,2.28483-1.073c1.07232-1.305,1.2125-2.27273,1.2125-7.3547C115.20169,22.07665,115.06151,21.06338,113.98919,19.757Z" />
                <path d="M132.50189,36.05457a6.08494,6.08494,0,0,1-8.76644,0c-1.44554-1.72625-1.77179-4.7575-1.77179-10.3518,0-5.59572.32625-8.627,1.77179-10.3518a5.97877,5.97877,0,0,1,8.76644,0c1.44554,1.72482,1.77179,4.75608,1.77179,10.3518C134.27368,31.29707,133.94743,34.32832,132.50189,36.05457ZM130.4501,16.8424a3.00279,3.00279,0,0,0-2.33143-1.073,3.10433,3.10433,0,0,0-2.378,1.073c-1.07268,1.30643-1.16589,3.777-1.16589,8.86037,0,5.082.09321,7.507,1.16589,8.812a3.10432,3.10432,0,0,0,2.378,1.073,3.00278,3.00278,0,0,0,2.33143-1.073c1.07268-1.305,1.2125-3.73,1.2125-8.812C131.6626,20.61937,131.52278,18.14883,130.4501,16.8424Z" />
                <path d="M148.58995,36.10011a6.22305,6.22305,0,0,1-4.523,1.81875,5.90509,5.90509,0,0,1-4.33626-1.67786,6.99622,6.99622,0,0,1-1.86536-5.13036h2.61143a4.59255,4.59255,0,0,0,1.21215,3.498,3.31779,3.31779,0,0,0,2.378.9791,3.6503,3.6503,0,0,0,2.70464-1.073,4.05557,4.05557,0,0,0,1.07233-2.93733,4.67461,4.67461,0,0,0-.65251-2.56447,6.1855,6.1855,0,0,0-2.37839-2.05214l-2.98429-1.63232a8.30861,8.30861,0,0,1-3.07751-2.79787,6.11992,6.11992,0,0,1-.65286-2.98429,6.25762,6.25762,0,0,1,1.86536-4.47572,5.87513,5.87513,0,0,1,4.2434-1.63232,5.70041,5.70041,0,0,1,4.24305,1.58536,6.896,6.896,0,0,1,1.72553,5.12894h-2.61143c0-1.77179-.27964-2.657-1.02571-3.44966a3.0559,3.0559,0,0,0-2.33144-.93357,3.351,3.351,0,0,0-2.47161.88661,3.86348,3.86348,0,0,0-1.02571,2.79786,4.0525,4.0525,0,0,0,.46607,2.05215,5.6047,5.6047,0,0,0,2.19161,1.77178l2.93768,1.67787a8.46661,8.46661,0,0,1,3.21769,2.79786,7.15735,7.15735,0,0,1,.9325,3.73A6.5091,6.5091,0,0,1,148.58995,36.10011Z" />
                <path d="M164.63175,36.05457a6.08494,6.08494,0,0,1-8.76644,0c-1.44554-1.72625-1.77179-4.7575-1.77179-10.3518,0-5.59572.32625-8.627,1.77179-10.3518a5.97877,5.97877,0,0,1,8.76644,0c1.44554,1.72482,1.77179,4.75608,1.77179,10.3518C166.40354,31.29707,166.07729,34.32832,164.63175,36.05457ZM162.58,16.8424a3.00283,3.00283,0,0,0-2.33144-1.073,3.10434,3.10434,0,0,0-2.378,1.073c-1.07268,1.30643-1.16589,3.777-1.16589,8.86037,0,5.082.09321,7.507,1.16589,8.812a3.10434,3.10434,0,0,0,2.378,1.073,3.00282,3.00282,0,0,0,2.33144-1.073c1.07267-1.305,1.2125-3.73,1.2125-8.812C163.79247,20.61937,163.65264,18.14883,162.58,16.8424Z" />
                <path d="M174.70462,21.87883V37.546h-2.61107V21.87883h-2.89108V19.82669h2.89108V16.42258a6.12447,6.12447,0,0,1,1.77179-4.80162,7.015,7.015,0,0,1,4.89625-1.53982v2.378c-2.658,0-4.057,1.30643-4.057,3.9634v3.40411h4.057v2.05214Z" />
                <path d="M184.91731,36.05457a6.239,6.239,0,0,1-1.77179-4.85V21.87883h-2.89107V19.82669h2.89107V12.69257h2.61108v7.13412h4.057v2.05214h-4.057v9.32573c0,2.84483,1.49215,4.01037,4.2434,4.01037V37.546C188.08839,37.546,186.31624,37.35957,184.91731,36.05457Z" />
            </g>
            <g>
                <path d="M26.67188,11.538,38.98744,6.61272A3.42925,3.42925,0,0,0,36.44113.24442L20.57143,6.59263,13.27316,3.673a3.42925,3.42925,0,0,0-2.54632,6.3683L14.471,11.538,1.00446,25.00446a3.42734,3.42734,0,0,0,0,4.84822L18.14732,46.99554a3.42734,3.42734,0,0,0,4.84822,0L40.13839,29.85268a3.42732,3.42732,0,0,0,0-4.84822ZM20.57143,39.72321,8.27679,27.42857,20.57143,15.13393,32.86607,27.42857Z" />
                <circle cx="3.42857" cy="3.42857" r="3.42857" />
            </g>
        </g>
    </svg>
);
