import { Button, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { IconSpinner } from '../components/IconSpinner';
import { SharedToolbar } from '../components/SharedToolbar';
import { InfoSection } from '../components/InfoSection';
import Color from 'color';
import { Banner } from '../components/Banner';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            zIndex: 90,
            position: 'relative',
            margin: 'auto',
            maxWidth: '800px',
            textAlign: 'center',
            color: 'white',
        },
    })
);

export const Home: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <SharedToolbar
                triggerElementId={'content-section-1'}
                colors={{ fixedBackground: Color(theme.palette.primary.dark).alpha(0.95).toString() }}
            />
            <Banner
                videoSource={'./images/loop.mp4'}
                imageSource={'./images/software.jpeg'}
                imageAlt={'People collaborating around a table'}
                scrollToId={'content-section-1'}
            >
                <div className={classes.content}>
                    <Typography
                        variant={'h3'}
                        sx={{ typography: { xs: 'h4', sm: 'h3' } }}
                    >{`User-Centered Software...`}</Typography>
                    <Typography
                        variant={'h6'}
                        sx={{ fontWeight: 400 }}
                    >{`We'll help you take your business into the digital future`}</Typography>
                    <Button
                        variant={'contained'}
                        component={Link}
                        to={'contact'}
                        size={'large'}
                        disableElevation
                        sx={{ margin: 4, textTransform: 'none' }}
                    >{`Let's work together!`}</Button>

                    <IconSpinner />
                </div>
            </Banner>

            <InfoSection
                id={'content-section-1'}
                name={'content-section-1'}
                direction={'right'}
                image={'images/whiteboard.jpg'}
                imageAlt={'A woman drawing a diagram on a whiteboard'}
                title={`What we offer...`}
                link={{ text: 'Learn More', href: '/services' }}
                body={[
                    `At Joebosoft, we offer a range of different services, depending on the nature and maturity of your project. For projects that are in the early stages, we provide user research services to understand more about the solution space and the people who wil be using your product. We translate these insights into key takeaways to make sure we're building something that meets your customers' needs.`,
                    `For projects that are further along, we provide prototyping, user testing, and full-scale development services to bring your ideas into reality.`,
                    `For larger businesses, we also offer consulting services to help you scale up your own software development teams and work more efficiently.`,
                ]}
            />
            <InfoSection
                name={'content-section-2'}
                direction={'left'}
                image={'images/computers.jpg'}
                imageAlt={'Two people collaborating on a document between their computers'}
                title={'Our approach...'}
                // link={{ text: 'Learn More', href: '/about' }}
                body={[
                    `We take a user-centered approach in everything that we do — and that starts from our very first meeting. After reaching out to our team, you can expect a response within 24 hours to set up an initial no-cost consultation to discuss your project and how we can best work together.`,
                    `We will work with you to develop a project plan that clearly outlines the project goals, deliverables, and milestones and communicate with you each step along the way. We offer several different pricing models to suit your project scope and budget.`,
                ]}
            />
            <InfoSection
                name={'content-section-3'}
                direction={'right'}
                image={'images/developing.jpg'}
                imageAlt={'Over the shoulder picture of a man working at a laptop'}
                title={'Why Joebosoft?'}
                body={[
                    `What separates Joebosoft from other software consultancies is the personal relationship we build with our clients and our uncompromising commitment to quality. We do not crank out hundreds of cookie-cutter projects a year like some larger operations. We operate on the small-scale, high-quality end of the spectrum, selecting projects that we truly believe in. When we work on your project, you can be sure that we care about its success as much as you do.`,
                ]}
            />
            <InfoSection
                name={'content-section-4'}
                direction={'left'}
                image={'images/local.jpg'}
                imageAlt={'Sign in a window that says thank you for shopping local'}
                title={'Supporting small businesses...'}
                body={[
                    'As a small business ourselves, Joebosoft is committed to helping other small businesses grow. If you are a local or independently-owned business, get in touch with us today to see how we can help you take the next step.',
                ]}
            />
        </>
    );
};
