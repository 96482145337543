import { HTMLAttributes } from 'react';

export const JoebosoftLogoStacked: React.FC<HTMLAttributes<SVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.83862 51.93408" {...props}>
        <g fill={'currentColor'}>
            <g>
                <path d="M12.17163,50.62256a4.756,4.756,0,0,1-3.45557,1.31152,4.48269,4.48269,0,0,1-3.616-1.59961l1.376-1.248a2.7434,2.7434,0,0,0,2.24,1.15234c1.63184,0,2.91163-1.18457,2.91163-3.45606V32.83154h1.88794V46.78271A5.24674,5.24674,0,0,1,12.17163,50.62256Z" />
                <path d="M24.13916,50.65479a3.63489,3.63489,0,0,1-3.00781,1.27929,3.6352,3.6352,0,0,1-3.00781-1.27929c-.99195-1.18458-1.21583-3.26465-1.21583-7.10352,0-3.83984.22388-5.91992,1.21583-7.10352a3.65449,3.65449,0,0,1,3.00781-1.3125,3.65418,3.65418,0,0,1,3.00781,1.3125c.99194,1.1836,1.21582,3.26368,1.21582,7.10352C25.355,47.39014,25.1311,49.47021,24.13916,50.65479Zm-1.408-13.1836a2.06056,2.06056,0,0,0-1.59985-.73633,2.13024,2.13024,0,0,0-1.63184.73633c-.73608.89649-.80005,2.5918-.80005,6.08008,0,3.4873.064,5.15137.80005,6.04687a2.13021,2.13021,0,0,0,1.63184.73633,2.06053,2.06053,0,0,0,1.59985-.73633c.73609-.8955.832-2.55957.832-6.04687C23.56323,40.063,23.46729,38.36768,22.7312,37.47119Z" />
                <path d="M29.99536,43.80713c0,3.32715.09595,4.959.832,5.791a2.07993,2.07993,0,0,0,1.63184.73633,2.14807,2.14807,0,0,0,1.63183-.63965,3.32022,3.32022,0,0,0,.73609-2.30468h1.75976A4.5917,4.5917,0,0,1,35.40308,50.814a4.10849,4.10849,0,0,1-2.94385,1.12012,3.78278,3.78278,0,0,1-2.94385-1.18359c-1.08789-1.15235-1.31177-3.2959-1.31177-7.19922,0-3.9043.22388-6.08008,1.31177-7.23242a3.75916,3.75916,0,0,1,2.91187-1.1836,3.7588,3.7588,0,0,1,2.91186,1.1836c1.15186,1.248,1.27979,3.55176,1.27979,7.48828Zm4.03174-6.33594a2.01146,2.01146,0,0,0-1.59985-.73633,2.08,2.08,0,0,0-1.63184.73633c-.63989.73633-.76807,2.14356-.8,4.89551h4.83179C34.79517,39.61475,34.667,38.20752,34.0271,37.47119Z" />
                <path d="M46.98706,50.78271a3.2502,3.2502,0,0,1-2.52783,1.15137,3.0435,3.0435,0,0,1-1.856-.57617,3.45811,3.45811,0,0,1-.928-.99121v1.31152H39.91553V32.83154h1.79175v5.77539a2.88063,2.88063,0,0,1,.896-.96,3.23728,3.23728,0,0,1,1.856-.51172,3.17017,3.17017,0,0,1,2.52783,1.15235c.99194,1.15136,1.21582,2.19922,1.21582,6.26367C48.20288,48.61475,47.979,49.63037,46.98706,50.78271Zm-1.408-11.31152a2.09578,2.09578,0,0,0-1.56787-.73633,2.20763,2.20763,0,0,0-1.53589.6084,2.99487,2.99487,0,0,0-.76806,2.24024v5.90332a3.07637,3.07637,0,0,0,.76806,2.27148,2.18114,2.18114,0,0,0,1.53589.57617,2.09575,2.09575,0,0,0,1.56787-.73633c.73584-.8955.832-1.55957.832-5.04687C46.41113,41.063,46.31494,40.36768,45.5791,39.47119Z" />
                <path d="M58.28271,50.65479a4.17556,4.17556,0,0,1-6.01562,0c-.99194-1.18458-1.21582-3.26465-1.21582-7.10352,0-3.83984.22388-5.91992,1.21582-7.10352a4.1027,4.1027,0,0,1,6.01562,0c.99195,1.1836,1.21583,3.26368,1.21583,7.10352C59.49854,47.39014,59.27466,49.47021,58.28271,50.65479Zm-1.40795-13.1836a2.06058,2.06058,0,0,0-1.59986-.73633,2.13022,2.13022,0,0,0-1.63183.73633c-.73609.89649-.8,2.5918-.8,6.08008,0,3.4873.064,5.15137.8,6.04687a2.13019,2.13019,0,0,0,1.63183.73633,2.06055,2.06055,0,0,0,1.59986-.73633c.73608-.8955.832-2.55957.832-6.04687C57.70679,40.063,57.61084,38.36768,56.87476,37.47119Z" />
                <path d="M69.32251,50.686a4.27033,4.27033,0,0,1-3.10376,1.248,4.05212,4.05212,0,0,1-2.97559-1.15137,4.80092,4.80092,0,0,1-1.28-3.5205h1.792a3.15146,3.15146,0,0,0,.83178,2.40039,2.27676,2.27676,0,0,0,1.63184.67187,2.50489,2.50489,0,0,0,1.856-.73633,2.783,2.783,0,0,0,.73584-2.01562,3.20776,3.20776,0,0,0-.44776-1.75977,4.24462,4.24462,0,0,0-1.63208-1.4082l-2.04785-1.12012A5.70153,5.70153,0,0,1,62.571,41.37451a4.19959,4.19959,0,0,1-.448-2.04785,4.294,4.294,0,0,1,1.28-3.07129,4.03153,4.03153,0,0,1,2.91186-1.12012,3.91166,3.91166,0,0,1,2.91162,1.08789,4.73213,4.73213,0,0,1,1.18408,3.51954h-1.792a3.04541,3.04541,0,0,0-.70386-2.36719,2.09694,2.09694,0,0,0-1.59985-.64063,2.29946,2.29946,0,0,0-1.696.6084,2.65113,2.65113,0,0,0-.70386,1.91992,2.78094,2.78094,0,0,0,.31982,1.40821,3.84607,3.84607,0,0,0,1.50391,1.21582l2.01587,1.15136a5.80988,5.80988,0,0,1,2.208,1.91993,4.91144,4.91144,0,0,1,.63989,2.55957A4.46658,4.46658,0,0,1,69.32251,50.686Z" />
                <path d="M80.33057,50.65479a4.17557,4.17557,0,0,1-6.01563,0c-.99194-1.18458-1.21582-3.26465-1.21582-7.10352,0-3.83984.22388-5.91992,1.21582-7.10352a4.10271,4.10271,0,0,1,6.01563,0c.99194,1.1836,1.21582,3.26368,1.21582,7.10352C81.54639,47.39014,81.32251,49.47021,80.33057,50.65479Zm-1.408-13.1836a2.06058,2.06058,0,0,0-1.59986-.73633,2.13023,2.13023,0,0,0-1.63183.73633c-.73609.89649-.8,2.5918-.8,6.08008,0,3.4873.064,5.15137.8,6.04687a2.1302,2.1302,0,0,0,1.63183.73633,2.06055,2.06055,0,0,0,1.59986-.73633c.73608-.8955.832-2.55957.832-6.04687C79.75464,40.063,79.65869,38.36768,78.92261,37.47119Z" />
                <path d="M87.24268,40.92725v10.751H85.45093v-10.751H83.467V39.519h1.98389V37.18311a4.20269,4.20269,0,0,1,1.21582-3.29493,4.81376,4.81376,0,0,1,3.35986-1.05664v1.63184c-1.824,0-2.78393.89648-2.78393,2.71973V39.519h2.78393v1.40821Z" />
                <path d="M94.25073,50.65479a4.28124,4.28124,0,0,1-1.21582-3.32813V40.92725H91.051V39.519h1.98388v-4.8955h1.79175V39.519H97.6106v1.40821H94.82666v6.39941c0,1.95215,1.02393,2.752,2.91187,2.752v1.59961A4.77393,4.77393,0,0,1,94.25073,50.65479Z" />
            </g>
            <g>
                <path d="M54.97765,6.73047l7.18408-2.87305A2.00039,2.00039,0,0,0,60.67638.14258L51.41906,3.8457,47.16173,2.14258a2.00039,2.00039,0,0,0-1.48535,3.71484l2.18408.87305L40.005,14.58594a1.99926,1.99926,0,0,0,0,2.82812l10,10a1.99927,1.99927,0,0,0,2.82813,0l10-10a1.99926,1.99926,0,0,0,0-2.82812ZM51.41906,23.17188,44.24718,16l7.17188-7.17188L58.59093,16Z" />
                <circle cx="41.41906" cy="2" r="2" />
            </g>
        </g>
    </svg>
);
