import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, StyledEngineProvider, ThemeOptions, ThemeProvider } from '@mui/material';
import { MDXProvider } from '@mdx-js/react';

import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import { componentsMap } from './__configuration__/markdowMapping';
import { init } from 'emailjs-com';
import keys from './__configuration__/email';
init(keys.USER_ID);

const jbcTheme: ThemeOptions = {
    typography: {
        fontFamily: '"Lato", Helvetica, Roboto, sans-serif',
        fontWeightMedium: 600,
        h6: {
            fontWeight: 600,
        },
        subtitle1: {
            fontWeight: 600,
        },
        subtitle2: {
            fontWeight: 600,
        },
        body1: {
            lineHeight: '1.5',
        },
        body2: {
            lineHeight: '1.43',
        },
        button: {
            fontWeight: 600,
            lineHeight: '1.75',
        },
        overline: {
            letterSpacing: '2px',
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: '2.67',
        },
        caption: {
            lineHeight: '1.67',
        },
    },
    palette: {
        primary: {
            main: '#008cff',
            light: '#80c6ff',
            dark: '#003661',
        },
        secondary: {
            main: '#004b4e',
            light: '#009499',
            dark: '#003133',
        },
        text: {
            primary: '#003661',
            secondary: '#424e54',
        },
    },
};

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme(jbcTheme)}>
                <CssBaseline />
                <MDXProvider components={componentsMap}>
                    <App />
                </MDXProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
