import React from 'react';
import { Box, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ElementProps } from 'react-scroll/modules/components/Element';
import clsx from 'clsx';

export type InfoSectionProps = ElementProps & {
    image: string;
    direction?: 'left' | 'right';
    imageAlt?: string;
    title: string;
    titleIcon?: JSX.Element;
    body: Array<string | JSX.Element>;
    link?: {
        text: string;
        href: string;
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        element: {
            overflow: 'hidden',
            padding: `128px 0`,
            [theme.breakpoints.down('md')]: {
                padding: '32px 0',
            },
            maxWidth: 1400,
            margin: 'auto',
        },
        section: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('md')]: {
                display: 'block',
            },
        },
        image: {
            width: '50vw',
            maxWidth: 700,
            opacity: 0.8,
            display: 'block',
            '&$left': {
                float: 'left',
            },
            '&$right': {
                float: 'right',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
                '&$left': {
                    float: 'none',
                },
                '&$right': {
                    float: 'none',
                },
            },
        },
        textWrapper: {
            marginLeft: 32,
            marginRight: 32,
            maxWidth: 500,
            [theme.breakpoints.down('md')]: {
                maxWidth: 'unset',
                margin: `32px 10vw`,
            },
        },
        left: {},
        right: {},
    })
);

const InfoSectionRender: React.ForwardRefRenderFunction<unknown, InfoSectionProps> = (
    props: InfoSectionProps,
    ref: any
) => {
    const { image, title, titleIcon, body, link, direction = 'left', imageAlt, ...other } = props;
    const classes = useStyles();

    return (
        <Element ref={ref} {...other} className={classes.element}>
            <section
                className={classes.section}
                style={{
                    flexDirection: direction === 'left' ? 'row' : 'row-reverse',
                }}
            >
                <Box sx={{ backgroundColor: 'primary.dark', maxWidth: 700, margin: { sm: 'auto', md: 0 } }}>
                    <img
                        src={image}
                        alt={imageAlt}
                        className={clsx(classes.image, direction === 'left' ? classes.left : classes.right)}
                    />
                </Box>
                <div className={classes.textWrapper}>
                    <Typography
                        variant={'h4'}
                        style={{ fontWeight: 500, marginBottom: 32, display: 'flex', alignItems: 'center' }}
                    >
                        {titleIcon}
                        {titleIcon && <Box style={{ width: 8 }} />}
                        {title}
                    </Typography>
                    {body.map((p, ind) =>
                        typeof p === 'string' ? (
                            <Typography key={`paragraph-${ind}`} style={{ fontWeight: 400 }}>
                                {p}
                            </Typography>
                        ) : (
                            p
                        )
                    )}
                    {link && (
                        <Typography
                            component={Link}
                            to={link.href}
                            sx={{
                                mt: 3,
                                display: 'inline-block',
                                fontWeight: 600,
                                '&:hover': { textDecoration: 'underline' },
                            }}
                        >
                            {`${link.text}...`}
                        </Typography>
                    )}
                </div>
            </section>
        </Element>
    );
};
export const InfoSection = React.forwardRef(InfoSectionRender);
