import { useRef, useState } from 'react';
import { Box, TextField, Typography, Grid, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import emailjs from 'emailjs-com';
import keys from '../__configuration__/email';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { SharedToolbar } from '../components/SharedToolbar';
import { Banner } from '../components/Banner';
import { Element } from 'react-scroll';

type FormValues = {
    from: string;
    message: string;
    email: string;
    submit: null;
};
const initialValues: FormValues = {
    from: '',
    message: '',
    email: '',
    submit: null,
};

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            height: 'auto',
            minHeight: 'unset',
            maxHeight: 'unset',
            padding: '10vh 50px',
            textAlign: 'center',
        },
    })
);

export const Contact: React.FC = () => {
    const classes = useStyles();
    const form = useRef(null);
    const [submitted, setSubmitted] = useState(false);

    const sendEmail = (values: FormValues, { setSubmitting, setErrors, resetForm }: FormikHelpers<FormValues>) => {
        emailjs.sendForm(keys.SERVICE_ID, keys.TEMPLATE_ID, form.current || '', keys.USER_ID).then(
            (result) => {
                if (result.status === 200) {
                    setSubmitting(false);
                    setSubmitted(true);
                    setErrors({
                        submit: 'There was an error submitting the form, please try again later or email us directly at info@joebosoft.com',
                    });

                    resetForm();
                } else {
                    setErrors({
                        submit: 'There was an error submitting the form, please try again later or email us directly at info@joebosoft.com',
                    });
                }
            },
            (error) => {
                setSubmitting(false);
                setSubmitted(true);
                setErrors({
                    submit: 'There was an error submitting the form, please try again later or email us directly at info@joebosoft.com',
                });
            }
        );
    };

    return (
        <>
            <SharedToolbar />
            <Banner scrollToId={'contact-form'} className={classes.wrapper}>
                <Box sx={{ maxWidth: 800, margin: 'auto', position: 'relative', zIndex: 90, color: 'white' }}>
                    <Typography variant={'h4'}>Contact Joebosoft</Typography>
                    <Typography sx={{ mt: 4, mb: 4 }}>
                        We're very excited to work with you on your upcoming project. Use the form below to tell us a
                        little bit about it — we typically respond within <strong>24 hours</strong>
                    </Typography>
                </Box>
            </Banner>
            <Element name={'contact-form'}>
                <Box sx={{ maxWidth: 800, margin: 'auto', padding: 8 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            from: Yup.string().required('Required'),
                            // company: Yup.string(),
                            message: Yup.string().required('Required'),
                            email: Yup.string().email('Please enter a valid email').required('Required'),
                        })}
                        onSubmit={sendEmail}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            /* and other goodies */
                        }) => (
                            <>
                                {submitted && !Boolean(errors.submit) && (
                                    <Typography variant={'h5'}>Your message was sent successfully</Typography>
                                )}
                                {submitted && Boolean(errors.submit) && (
                                    <Typography variant={'h5'} color={'error'}>
                                        {errors.submit}
                                    </Typography>
                                )}
                                {!submitted && (
                                    <form ref={form} onSubmit={handleSubmit}>
                                        <Grid container spacing={2} justifyContent={'flex-end'}>
                                            <Grid item sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    name={'from'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.from}
                                                    label={'Your Name'}
                                                    error={Boolean(errors.from) && touched.from}
                                                    helperText={Boolean(errors.from) && touched.from && errors.from}
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    name={'email'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    label={'Your Email'}
                                                    error={Boolean(errors.email) && touched.email}
                                                    helperText={Boolean(errors.email) && touched.email && errors.email}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={8}
                                                    name={'message'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                    label={'Your Message'}
                                                    placeholder={'Tell us about your project...'}
                                                    error={Boolean(errors.message) && touched.message}
                                                    helperText={
                                                        Boolean(errors.message) && touched.message && errors.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    fullWidth
                                                    variant={'contained'}
                                                    type={'submit'}
                                                    disabled={!(isValid && dirty && !isSubmitting)}
                                                >
                                                    SEND
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </>
                        )}
                    </Formik>
                </Box>
            </Element>
        </>
    );
};
