import { ComponentType } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SharedToolbar } from '../components/SharedToolbar';
import { PsychologyOutlined, BrushOutlined, DevicesOutlined, ArchitectureOutlined, Groups } from '@mui/icons-material';
import { InfoSection } from '../components/InfoSection';
import { Banner } from '../components/Banner';
import { scroller } from 'react-scroll';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            height: 'auto',
            minHeight: 'unset',
            maxHeight: 'unset',
            padding: '10vh 50px',
            textAlign: 'center',
        },
        serviceCard: {
            '&:hover > $serviceCardIcon': {
                opacity: 1,
            },
        },
        serviceCardIcon: {
            opacity: 0.5,
        },
    })
);

export const ServiceCard: React.FC<{ Icon: ComponentType<any>; label: string; scrollToId: string }> = (props) => {
    const { Icon, label, scrollToId } = props;
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid item xs={12} sm={4} md={2}>
            <Box
                sx={{ textAlign: 'center', cursor: 'pointer' }}
                className={classes.serviceCard}
                onClick={(): void => {
                    scroller.scrollTo(scrollToId, {
                        duration: 350,
                        delay: 0,
                        smooth: true,
                        offset: 0,
                    });
                }}
            >
                <Icon style={{ fontSize: sm ? 48 : 80 }} className={classes.serviceCardIcon} />
                <Typography variant={'body1'} sx={{ fontWeight: 400 }}>
                    {label}
                </Typography>
            </Box>
        </Grid>
    );
};

export const Services: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <SharedToolbar scrollThreshold={window.innerHeight - 200} />
            <Banner scrollToId={'ux-research-description'} className={classes.wrapper}>
                <Box sx={{ margin: 'auto', position: 'relative', zIndex: 90, color: 'white' }}>
                    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
                        <Typography variant={'h4'}>Services</Typography>
                        <Typography sx={{ mt: 4, mb: 4 }}>
                            We offer a variety of services that span the entire software development lifecycle from
                            discovery to design, development, and delivery. No matter your project size, we have
                            solutions that can help you on your digital journey. Learn more about our offerings below.
                        </Typography>
                    </Box>
                    <Box sx={{ maxWidth: 1400, margin: 'auto' }}>
                        <Grid container spacing={xs ? 4 : sm ? 6 : 8} justifyContent={'center'}>
                            <ServiceCard
                                Icon={PsychologyOutlined}
                                label={'User-Centered Research'}
                                scrollToId={'ux-research-description'}
                            />
                            <ServiceCard
                                Icon={BrushOutlined}
                                label={'Software Design & Prototyping'}
                                scrollToId={'software-design-description'}
                            />
                            <ServiceCard
                                Icon={DevicesOutlined}
                                label={'Web & Mobile App Development'}
                                scrollToId={'app-development-description'}
                            />
                            <ServiceCard
                                Icon={ArchitectureOutlined}
                                label={'Design System Architecture'}
                                scrollToId={'design-system-description'}
                            />
                            <ServiceCard
                                Icon={Groups}
                                label={'Scaling Software Teams'}
                                scrollToId={'building-teams-description'}
                            />
                        </Grid>
                    </Box>
                </Box>
            </Banner>
            <InfoSection
                name={'ux-research-description'}
                direction={'left'}
                image={'images/research.jpg'}
                imageAlt={'People collaborating around a table'}
                title={`User-Centered Research`}
                body={[
                    `User research is the process by which we determine whether or not we are solving the right problem. This involves talking to stakeholders and potential customers to identify opportunities for new products or to validate existing ideas. At Joebosoft, we are well-versed in a variety of user research techniques, including:`,
                    <Typography key={'li-1'} component={'li'} sx={{ mt: 2 }}>
                        Interviewing
                    </Typography>,
                    <Typography key={'li-2'} component={'li'}>
                        Contextual Inquiry
                    </Typography>,
                    <Typography key={'li-3'} component={'li'}>
                        Market Research
                    </Typography>,
                    <Typography key={'li-4'} component={'li'}>
                        Experience Diagramming
                    </Typography>,
                    <Typography key={'li-5'} component={'li'}>
                        Stakeholder Mapping
                    </Typography>,
                    `We then take this raw data and extract out key insights to help guide the decision-making process for your project. These insights could indicate that your concept is ready to go as-is, or they may suggest that some modifications or more research are required. Either way, we'll be there to guide you through the process and help make sure that we are building the right product for your customers.`,
                ]}
            />
            <InfoSection
                name={'software-design-description'}
                direction={'right'}
                image={'images/design.jpg'}
                imageAlt={'People collaborating around a table'}
                title={`Software Design & Prototyping`}
                body={[
                    `If you've got a great idea for a product, but not the technical chops to visualize it on paper, let our visual designers give you a helping hand. We can operate at multiple levels of fidelity, from low-fidelity sketches up to high-fidelity vector graphics and clickable prototypes. Whether you simply need to map out your information architecture, or you need detailed specifications that you can hand off to your team for implementation, we've got you covered. `,
                    <Typography key={'li-1'} component={'li'} sx={{ mt: 2 }}>
                        Branding / Logos
                    </Typography>,
                    <Typography key={'li-2'} component={'li'}>
                        Iconography
                    </Typography>,
                    <Typography key={'li-3'} component={'li'}>
                        Application Design
                    </Typography>,
                ]}
            />
            <InfoSection
                name={'app-development-description'}
                direction={'left'}
                image={'images/apps.jpg'}
                imageAlt={'People collaborating around a table'}
                title={`Web & Mobile App Development`}
                body={[
                    `When you're ready to breathe life into your project, we'll be at the ready. We specialize in building applications for the web and mobile devices using the latest cutting-edge technology and tools. All of our code comes fully tested, fully documented, and ready for production.`,
                    <Typography key={'li-1'} component={'li'} sx={{ mt: 2 }}>
                        Responsive Web Applications
                    </Typography>,
                    <Typography key={'li-2'} component={'li'}>
                        Marketing Websites
                    </Typography>,
                    <Typography key={'li-3'} component={'li'}>
                        E-Commerce Applications
                    </Typography>,
                    <Typography key={'li-4'} component={'li'}>
                        iOS &amp; Android Applications
                    </Typography>,
                    `We build mobile applications using cross-platform technologies that save time and money. With a single code base, we can deploy applications for Android and iOS devices without having to build separate apps for each platform. These applications are easier to build and easier to maintain for the future.`,
                ]}
            />
            <InfoSection
                name={'design-system-description'}
                direction={'right'}
                image={'images/design-system.jpg'}
                imageAlt={'People collaborating around a table'}
                title={`Design System Architecture`}
                body={[
                    `If you are a larger company with multiple products, you should consider developing a design system to promote consistency and re-usability across all of your products. We have years of experience building design systems for Fortune 500 companies, helping them minimize their time-to-market while establishing a strong, instantly-recognizable brand identity.`,
                    `We can help assess your existing product lines, identify opportunities for better alignment, and provide recommendations for building a robust design system architecture.`,
                    `Developing a design system is a great future-proofing idea for smaller businesses as well — giving you room to grow without the growing pains.`,
                ]}
            />
            <InfoSection
                name={'building-teams-description'}
                direction={'left'}
                image={'images/teams.jpg'}
                imageAlt={'People collaborating around a table'}
                title={`Scaling Software Teams`}
                body={[
                    `When you are ready to start doing your own software development in house, we can help you build a highly-effective team and set them up for success. We can share our lessons learned from building our own teams, including tips for:`,
                    <Typography key={'li-1'} component={'li'} sx={{ mt: 2 }}>
                        Hiring
                    </Typography>,
                    <Typography key={'li-2'} component={'li'}>
                        Agile Development
                    </Typography>,
                    <Typography key={'li-3'} component={'li'}>
                        Automation
                    </Typography>,
                    <Typography key={'li-4'} component={'li'}>
                        Continuous Integration / Continuous Delivery
                    </Typography>,
                    <Typography key={'li-5'} component={'li'}>
                        Quality Assurance
                    </Typography>,
                    <Typography key={'li-6'} component={'li'}>
                        Tools &amp; Processes
                    </Typography>,
                    `We can consult with your team to put these best practices and policies in place so you can hit the ground running on your next project.`,
                ]}
            />
        </>
    );
};
