import { Theme, Typography, Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { PsychologyOutlined, BrushOutlined, DevicesOutlined, ArchitectureOutlined, Groups } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            display: 'inline-block',
        },
    })
);

export const IconSpinner: React.FC = () => {
    const classes = useStyles();
    const [activeItem, setActiveItem] = useState(0);
    // const theme = useTheme();

    const items = [
        {
            component: PsychologyOutlined,
            label: 'User-Centered Research',
        },
        {
            component: BrushOutlined,
            label: 'Software Design & Prototyping',
        },
        {
            component: DevicesOutlined,
            label: 'Web & Mobile App Development',
        },
        {
            component: ArchitectureOutlined,
            label: 'Design System Architecture',
        },
        {
            component: Groups,
            label: 'Scaling Software Teams',
        },
    ];

    return (
        <Box sx={{ fontSize: { xs: 48, sm: 80 }, mt: 2, mb: 2 }}>
            <Carousel
                centerMode
                centerSlidePercentage={20}
                autoPlay
                infiniteLoop
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                stopOnHover={false}
                swipeable={false}
                onChange={setActiveItem}
                children={items.map((item, index) => (
                    <item.component
                        key={index}
                        fontSize={'inherit'}
                        className={classes.icon}
                        style={{ opacity: index === activeItem ? 1 : 0.5 }}
                    />
                ))}
            />
            <Typography variant={'h5'}>{items[activeItem].label}</Typography>
        </Box>
    );
};
