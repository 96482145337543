import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { ScrollToTop } from '../components/ScrollToTop';
import { Home, /*About,*/ Services, /*Portfolio, Blog,*/ Contact } from '../pages';
// import { SharedToolbar } from './SharedToolbar';

export const MainRouter: React.FC = () => {
    return (
        <Router>
            <>
                {/* <SharedToolbar /> */}
                <ScrollToTop />
                <Switch>
                    {/* <Route exact path="/about">
                        <About />
                    </Route> */}
                    <Route exact path="/services">
                        <Services />
                    </Route>
                    {/* <Route path="/portfolio">
                        <Portfolio />
                    </Route> */}
                    {/* <Route path="/blog">
                        <Blog />
                    </Route> */}
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path={'*'}>
                        <Redirect to={'/'} />
                    </Route>
                </Switch>
                <Footer />
            </>
        </Router>
    );
};
