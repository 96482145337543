import { Typography, TypographyProps, useTheme } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

export const ExternalLink: React.FC<TypographyProps<'a'>> = (tProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Typography
            component={'a'}
            target={'_blank'}
            rel={'noopener noreferrer'}
            style={{ fontWeight: 400, textDecoration: 'none', color: theme.palette.primary.main }}
            {...tProps}
        />
    );
};

export const InternalLink: React.FC<LinkProps> = (props) => {
    const theme = useTheme();
    return (
        <Link
            rel={'noopener noreferrer'}
            style={{ fontWeight: 400, textDecoration: 'none', color: theme.palette.primary.main }}
            {...props}
        />
    );
};

// export const BlockQuote: React.FC<TypographyProps<'blockquote'>> = (props) => {
//     const theme = useTheme();
//     return (
//         <Typography
//             component={'blockquote'}
//             style={{
//                 paddingRight: 16,
//                 marginBottom: 8,
//                 backgroundColor: color(theme.palette.primary.main).fade(0.9).string(),
//                 borderLeftColor: theme.palette.primary.main,
//                 // ...REGULAR_WIDTH_STYLE,
//             }}
//             {...props}
//         />
//     );
// }
// export const Pre: React.FC<TypographyProps<'pre'>> = (props) => {
//     const theme = useTheme();
//     return (
//         <Typography
//             component={'pre'}
//             color={'textPrimary'}
//             style={{
//                 paddingRight: 16,
//                 marginBottom: 8,
//                 display: 'flex',
//                 backgroundColor: theme.palette.background.default,
//                 // ...REGULAR_WIDTH_STYLE,
//             }}
//             {...props}
//         />
//     );
// }
// export const Code: React.FC<TypographyProps<'code'>> = (props) => {
//     const theme = useTheme();
//     return (
//         <Typography
//             component={'code'}
//             color={'textPrimary'}
//             style={{
//                 backgroundColor: theme.palette.background.default,
//                 fontFamily: 'Roboto Mono, Monospaced',
//                 fontSize: 12,
//             }}
//             {...props}
//         />
//     );
// }
// export const InlineCode: React.FC<TypographyProps<'code'>> = (props) => {
//     const theme = useTheme();
//     return (
//         <Typography
//             component={'code'}
//             color={'textPrimary'}
//             style={{
//                 backgroundColor: theme.palette.background.default,
//                 fontFamily: 'Roboto Mono, Monospaced',
//             }}
//             {...props}
//         />
//     );
// }

export const componentsMap = {
    h1: (props: TypographyProps): JSX.Element => <Typography variant={'h1'} {...props} />,
    h2: (props: TypographyProps): JSX.Element => <Typography variant={'h2'} {...props} />,
    h3: (props: TypographyProps): JSX.Element => <Typography variant={'h3'} {...props} />,
    h4: (props: TypographyProps): JSX.Element => <Typography variant={'h4'} {...props} />,
    h5: (props: TypographyProps): JSX.Element => <Typography variant={'h5'} {...props} />,
    h6: (props: TypographyProps): JSX.Element => <Typography variant={'h6'} {...props} />,
    a: (props: TypographyProps<'a'> | LinkProps): JSX.Element => {
        let tProps;
        if (props.href && (props.href.match(/^http/gi) || props.href.match(/^mailto/gi))) {
            tProps = props as TypographyProps<'a'>;
            return <ExternalLink {...tProps} />;
        }
        tProps = props as LinkProps;
        // @ts-ignore
        return <InternalLink to={props.href} {...tProps} />;
    },
    p: (props: TypographyProps): JSX.Element => (
        <Typography /*style={{ ...REGULAR_WIDTH_STYLE }}*/ paragraph {...props} />
    ),
    li: (props: TypographyProps<'li'>): JSX.Element => (
        <Typography component={'li'} className={'mdLi'} /*style={{ ...REGULAR_WIDTH_STYLE }}*/ {...props} />
    ),
    // blockquote: BlockQuote,
    // pre: Pre,
    // code: Code,
    // inlineCode: InlineCode,
};
