import { ExpandMore } from '@mui/icons-material';
import { IconButton, Theme, Box, BoxProps } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { scroller } from 'react-scroll';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: `25vh 50px`,
            background: theme.palette.primary.dark,
            display: 'block',
            position: 'relative',
            height: '90%',
            minHeight: 800,
            maxHeight: 1600,
            overflow: 'hidden',
            marginTop: -64,
            [theme.breakpoints.down('sm')]: {
                marginTop: -56,
                padding: `20vh 10vw`,
            },
        },
        bgVideo: {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.4,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
        },
    })
);

type BannerProps = BoxProps & {
    imageSource?: string;
    imageAlt?: string;
    videoSource?: string;
    scrollToId?: string;
};
export const Banner: React.FC<BannerProps> = (props) => {
    const { imageSource, imageAlt, videoSource, scrollToId, className, ...boxProps } = props;
    const classes = useStyles();

    return (
        <Box className={clsx(classes.wrapper, className)} {...boxProps}>
            {videoSource && (
                <video
                    autoPlay
                    playsInline
                    muted
                    loop
                    className={classes.bgVideo}
                    poster={imageSource}
                    src={videoSource}
                >
                    <img src={imageSource} alt={imageAlt} />
                </video>
            )}
            {!videoSource && imageSource && <img src={imageSource} alt={imageAlt} className={classes.bgVideo} />}
            {props.children}
            {scrollToId && (
                <IconButton
                    onClick={(): void => {
                        scroller.scrollTo(scrollToId, {
                            duration: 1000,
                            delay: 0,
                            smooth: true,
                            offset: -100,
                        });
                    }}
                    size={'large'}
                    sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white',
                    }}
                >
                    <ExpandMore fontSize={'inherit'} />
                </IconButton>
            )}
        </Box>
    );
};
